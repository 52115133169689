import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledLogo = styled.img`
  width: 100%;
  height: auto;
  
`;

export const StyledLogoWrapper = styled.div`
  margin: ${({ marginEnable }) => (marginEnable ? styles.spacing.larger : 0)}px
    ${({ centered }) => (centered ? "auto" : 0)} 0;
  width: ${({ width }) => width || 200}px;
  max-width: 600px;
  @media (max-width: 780px) {
    margin: 0 auto;
    width: ${({ scaled, width }) => (scaled ? 200 : width ? width : 200)}px;
  }

  @media (max-width: 1024px) {
    width: ${({ scaled, width }) => (scaled ? 240 : width ? width : 200)}px;
  }
`;
