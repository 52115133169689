import PropTypes from "prop-types";
import {
  StyledOrderItemWrapper,
  StyledItemContentWrapper,
  ImgWrapper,
  ProduktImg,
} from "./OrderItem.styled";
import Text from "../Text";
import useMediaQuery from "../../hooks/useMediaQuery"
import {labels} from "../../static/productHeaderLabels"

export default function OrderItem({ data, isOdd }) {
  const mediaIsMatched = useMediaQuery('(max-width: 600px)')
  const renderItem = (data) =>
    Object.entries(data)?.map(([itemKey, itemValue], _, entries) => (
      <StyledItemContentWrapper
        key={itemKey}
        isOdd={isOdd}
        quantity={entries.length}
      >
        {itemKey === labels.img ? (
          <ImgWrapper>
            <ProduktImg alt="zdjęcie produktu" src={itemValue} />
          </ImgWrapper>
        ) : (
          <>
          {mediaIsMatched && <Text bold>{itemKey}:{" "}</Text>}
          <Text fullWidth align="left">
            {itemValue}
          </Text>
          </>
        )}
      </StyledItemContentWrapper>
    ));
  return <StyledOrderItemWrapper>{renderItem(data)}</StyledOrderItemWrapper>;
}

OrderItem.propTypes = {
  data: PropTypes.object.isRequired,
  isOdd: PropTypes.bool,
};

OrderItem.defaultProps = {
  data: {},
};
