import PropTypes from "prop-types";
import { StyledHeaderText } from "./HeaderText.styled";
import { styles } from "../../GlobalStyle";

const {
  fontSizes: { huge, largest, larger, large, normal, small },
} = styles;

const sizes = {
  h1: huge,
  h2: largest,
  h3: larger,
  h4: large,
  h5: normal,
  h6: small,
};

export default function HeaderText({ children, displayAs, ...rest }) {
  return (
    <StyledHeaderText
      size={rest.size || sizes[displayAs]}
      displayAs={displayAs}
      {...rest}
    >
      {children}
    </StyledHeaderText>
  );
}

HeaderText.propTypes = {
  displayAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
};

HeaderText.defaultProps = {
  displayAs: "h2",
};
