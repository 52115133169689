import {
  GLOBAL_ACTIONS_TYPES,
  GLOBAL_STATE_PROPS,
  GLOBAL_INITIAL_STATE,
} from "./static";

const {
  BUYER_DATA,
  RECIPIENT_DATA,
  ORDER_DATA,
  ORDER_ITEMS,
  IS_LOADED,
  IS_LOADING,
  HAS_ERROR,
  LOGO_URL,
} = GLOBAL_STATE_PROPS;

const {
  SAVE_DATA,
  START_LOADING,
  FINISH_LOADING,
  SET_ERROR,
  RESET_DATA,
  RESET_ERROR,
} = GLOBAL_ACTIONS_TYPES;

const {
  [IS_LOADED]: INITIAL_IS_LOADED,
  [IS_LOADING]: INITIAL_IS_LOADING,
  [HAS_ERROR]: INITIAL_HAS_ERROR,
  ...INITIAL_DATA
} = GLOBAL_INITIAL_STATE;

export default function globalReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_DATA: {
      const { buyerData, recipientData, orderData, orderItems, logoUrl } =
        payload.data || {};
      return {
        ...state,
        [BUYER_DATA]: buyerData,
        [RECIPIENT_DATA]: recipientData,
        [ORDER_DATA]: orderData,
        [ORDER_ITEMS]: orderItems,
        [LOGO_URL]: logoUrl,
      };
    }

    case START_LOADING: {
      return { ...state, [IS_LOADING]: true };
    }

    case FINISH_LOADING: {
      return {
        ...state,
        [IS_LOADING]: false,
        [IS_LOADED]: payload.isLoaded,
        [HAS_ERROR]: true,
      };
    }

    case SET_ERROR: {
      return { ...state, [HAS_ERROR]: true };
    }

    case RESET_ERROR: {
      return { ...state, [HAS_ERROR]: INITIAL_HAS_ERROR };
    }

    case RESET_DATA: {
      return { ...state, ...INITIAL_DATA };
    }

    default:
      return state;
  }
}
