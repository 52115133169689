import { StyledText } from "./Text.styled";
import PropTypes from "prop-types";

export default function Text({ children, displayAs, ...rest }) {
  return (
    <StyledText as={displayAs || undefined} {...rest}>
      {children}
    </StyledText>
  );
}

Text.propTypes = {
  color: PropTypes.string,
  bold: PropTypes.bool,
  size: PropTypes.number,
  bright: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  displayAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"]),
  fullWidth: PropTypes.bool,
};

Text.defaultProps = {
  children: "",
  bold: false,
  size: 16,
  bright: false,
  align: "left",
  displayAs: "p",
  color: undefined,
};
