import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledText = styled.p`
  font-size: ${({ size }) => size || styles.fontSizes.normal}px;
  font-family: ${({ bold }) =>
    bold ? styles.fonts.main.bold : styles.fonts.main.light};
  color: ${({ bright, color }) =>
    color ? color : bright ? styles.colors.white : styles.colors.text};
  text-align: ${({ align }) => align || "left"};
  word-wrap: break-word;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  line-height: 1.4;
`;
