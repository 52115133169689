import Loader from "react-spinners/HashLoader";
import { spinnerStyleMixin } from "./LoadingIndicator.styled";
import { styles } from "../../GlobalStyle";

export default function LoadingIndicator({ isLoading }) {
  return (
    <Loader
      color={styles.colors.secondary}
      loading={isLoading}
      css={spinnerStyleMixin}
      size={92}
    />
  );
}
