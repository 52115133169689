import styled from "styled-components";
import { styles } from "../../GlobalStyle";
import myImg from './../../static/img/logo/tlo.PNG';

export const StyledMainWrapper = styled.main`
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-height: 75vh;
  margin: 0 auto;
  padding: 32px 32px 37px;
  box-shadow: rgb(242 242 242) 0px 0px 10px 5px;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 15px;
  border: 1px solid #eaeaea;

  @media (max-width: 460px) {
    padding: ${styles.spacing.small}px;
    padding-bottom: ${styles.spacing.larger * 2}px;
  }
`;
