import { GLOBAL_STATE_PROPS } from "../state/global/static";
import { getPaymentStatus } from "../static/paymentStatuses";

const mock = {
  address: {
    City: "Miękinia",
    Street: "Brzezinka Średzka, Ul.Pocztowa 1",
    StreetNumber: "",
    ZipCode: "55-330",
    Country: "Polska",
  },
  enable: false,
};

const nominatimSearchServiceUrlGetter = (formatedAddress) =>
  `https://nominatim.openstreetmap.org/search.php?${formatedAddress}&polygon_geojson=1&format=jsonv2`;

let xSaleService = null;

class XSaleServiceClass {
  DASH_INDEXES = [8, 13, 18, 23];
  dashMap = new Map();
  fguidChunksLenghts;
  ORDER_DATA_KEYS;
  API_URL;
  response;
  errors = [];
  cooridinates = { lat: null, lon: null };
  geoData;
  formatedAddress;
  fguidReguiredLength;
  _geoSearchURLGetter;

  constructor({
    orderDataKeys = GLOBAL_STATE_PROPS,
    geoSearchURLGetter = nominatimSearchServiceUrlGetter,
    apiUrl = process.env.REACT_APP_APIURL,
    fguidReguiredLength = 32,
  } = {}) {
    if (xSaleService) {
      return xSaleService;
    }
    xSaleService = this;
    this._geoSearchURLGetter = geoSearchURLGetter;
    this.ORDER_DATA_KEYS = orderDataKeys;
    this.API_URL = apiUrl;
    this.fguidReguiredLength = fguidReguiredLength;
    this._makeDashMap();
    this._setFguidChunksLenghts();
  }

  async getOrderData(fguid, { onResolved, onRejected } = {}) {
    try {
      const orderData = await this._handleGettingOrderData(fguid);
      onResolved && onResolved(orderData);
      return orderData;
    } catch (err) {
      console.error(err);
      this.errors.push(err);
      onRejected && onRejected();
      return { error: true, content: this.errors };
    }
  }

  formatFguid(inputFguid) {
    return this.DASH_INDEXES.reduce(
      (formatingFguid, dashIndex) =>
        formatingFguid.length < dashIndex
          ? formatingFguid
          : `${formatingFguid.slice(0, dashIndex)}-${formatingFguid.slice(
              dashIndex
            )}`,
      inputFguid
    );
  }

  flatFguidToInput(formatedFguid) {
    return String(formatedFguid).replaceAll("-", "");
  }

  validFguid(inputFguid) {
    return /^.{32}$/.test(inputFguid);
  }

  async _handleGettingOrderData(fguid) {
    this._resetData();
    await this._fetchOrderData(fguid);
    this._getAddressItemsFromResponse();
    await this._fetchGeoDataByAddress();
    if (!this.geoData) {
      this._getAddressItemsFromResponse({ isRepeated: true });
      await this._fetchGeoDataByAddress();
    }
    this._setCoordinatesFromGeoData();
    return this._mapResponseData();
  }

  _makeDashMap() {
    [...this.DASH_INDEXES]
      .reverse()
      .forEach((dashIndex, arrIndex, indexesArr) =>
        this.dashMap.set(dashIndex, indexesArr.length - arrIndex)
      );
  }

  _resetData() {
    this.response = null;
    this.geoData = null;
    this.formatedAddress = null;
    this.errors.length = 0;
  }

  async _fetchOrderData(fguid) {
    try {
      const response = await fetch(`${this.API_URL}/${fguid}`, {
        method: "GET",
        redirect: "follow",
        headers: { Accept: "application/json" },
      });

      if (!response.ok) {
        throw Error("The order could not be retrieved");
      }

      this.response = await response.json();
    } catch (err) {
      console.error(err);
      this.errors.push(err);
    }
  }

  async _fetchGeoDataByAddress() {
    try {
      if (!this.formatedAddress) {
        throw Error("invalid formated address");
      }
      const response = await fetch(
        this._geoSearchURLGetter(this.formatedAddress),
        {
          method: "GET",
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw Error("The geo data could not be retrieved");
      }

      [this.geoData] = await response.json();
    } catch (err) {
      console.error(err);
      this.errors.push(err);
    }
  }

  // _formatAddress(...addressItems) {
  //   this.formatedAddress = addressItems
  //     .filter(Boolean)
  //     .join("+")
  //     .replaceAll(" ", "+");
  // }

  _formatAddress({ Street, StreetNumber, City, Country, ZipCode }, isRepeated) {
    const matched = Street.match(/\s\d+\w?\/?.+/);

    if (Array.isArray(matched)) {
      const [substring] = matched;

      if (!StreetNumber && StreetNumber !== 0) {
        StreetNumber = substring?.trim();
        StreetNumber = StreetNumber.replace(/\/.+/, "");
      }
      Street = Street.replace(substring, "");
    }

    if (isRepeated) {
      const [streetFirstPary, streetSecondPart] = Street?.split(",");
      if (streetSecondPart) {
        Street = streetFirstPary;
      } else {
        City = "";
      }
    }

    if (StreetNumber || StreetNumber === 0) {
      Street += ` ${StreetNumber}`;
    }
    this.formatedAddress = `street=${Street}&city=${City}&country=${Country}&postalcode=${ZipCode}`;
  }

  _setFguidChunksLenghts() {
    this.fguidChunksLenghts = [
      ...this.DASH_INDEXES,
      this.fguidReguiredLength + this.DASH_INDEXES.length,
    ].reduce((chunksLengths, dashIndex, arrayIndex, array) => {
      chunksLengths.push(
        !arrayIndex ? dashIndex : dashIndex - array[arrayIndex - 1] - 1
      );
      return chunksLengths;
    }, []);
  }

  _getAddressItemsFromResponse({ isRepeated = false } = {}) {
    if (!this.response) {
      this.formatedAddress = "";
      return;
    }
    let { Street, StreetNumber, City, Country, ZipCode } = mock.enable
      ? mock.address
      : this.response.RecipientAddress;

    return this._formatAddress(
      {
        Street,
        StreetNumber,
        ZipCode,
        City,
        Country,
      },
      isRepeated
    );
  }

  _setCoordinatesFromGeoData() {
    if (!this.geoData) {
      return;
    }
    const { lat, lon } = this.geoData;
    this.cooridinates.lat = parseFloat(lat);
    this.cooridinates.lon = parseFloat(lon);
  }

  _toDisplay(divider = " ", ...items) {
    return items
      .filter((item) => item || item === 0)
      .reduce(
        (accString, item, index, arr) =>
          (accString += item
            ? `${item}${index !== arr.length - 1 ? divider : ""}`
            : ""),
        ""
      );
  }

  _mapResponseData() {
    const mappedData = {};

    if (!this.response) {
      throw Error("response is not exist");
    }

    const {
      BUYER_DATA,
      RECIPIENT_DATA,
      ORDER_DATA,
      ORDER_ITEMS,
      LOGO_URL,
    } = this.ORDER_DATA_KEYS;

    const {
      Status,
      PurchaseDate,
      DeliveryMethod,
      Comments,
      PaymentForm,
      PaymentStatus,
      Waybill,
      Items,
      BuyerAddress,
      RecipientAddress,
      Id,
      Courier,
      Organization,
    } = this.response || {};

    mappedData[ORDER_ITEMS] = Items;

    mappedData[LOGO_URL] = Organization?.PhotoUrl;

    mappedData[ORDER_DATA] = {
      number: Id,
      date: PurchaseDate,
      status: Status?.Name,
      paymentForm: PaymentForm?.Name,
      paymentStatus: getPaymentStatus(PaymentStatus),
      deliveryMethod: DeliveryMethod?.Name,
      waybillNumber: Waybill,
      additionalComments: Comments,
      provider: Courier,
      coordinates: {
        from: [],
        to: [this.cooridinates.lat, this.cooridinates.lon],
      },
    };

    mappedData[RECIPIENT_DATA] = this._getPersonalData(RecipientAddress ?? {});
    mappedData[BUYER_DATA] = this._getPersonalData(BuyerAddress ?? {});

    return mappedData;
  }

  _getPersonalData(sourceObject) {
    return {
      firstName: sourceObject?.Name,
      lastName: sourceObject?.Name2,
      thirdName: sourceObject?.Name3,
      nipNumber: sourceObject?.TaxNumber,
      address: this._toDisplay(
        "/",
        sourceObject?.Street,
        sourceObject?.StreetNumber
      ),
      zipCode: sourceObject?.ZipCode,
      locality: sourceObject?.City,
      country: sourceObject?.Country,
      phoneNumber: sourceObject?.MobilePhone,
      emailAddress: sourceObject?.Email,
    };
  }
}

export default new XSaleServiceClass();
