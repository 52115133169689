import styled from "styled-components";
import Text from "../Text";
import { styles } from "../../GlobalStyle";

export const StyledFguidInputWrapper = styled.div`
  position: relative;
  display: flex;
  margin: ${styles.spacing.verySmall}px auto;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const StyledDash = styled(Text)`
  color: ${styles.colors.accent};
`;

export const StyledFguidInputPart = styled.input`
  position: relative;
  background-color: ${styles.colors.lightGray};
  border-radius: 8px;
  font-size: ${styles.fontSizes.large}px;
  margin: ${styles.spacing.small}px;
  text-align: center;
  color: ${styles.colors.accent};

  @media (max-width: 460px) {
    font-size: ${styles.fontSizes.normal}px;
    margin: ${styles.spacing.verySmall}px;
    padding: ${styles.spacing.verySmall / 2}px;
  }

  &::placeholder {
    color: #dddddd;
  }

  min-width: 60px;
  width: ${({ maxLength }) =>
    maxLength ? (maxLength * styles.fontSizes.large) / 10 : 20}%;
`;
