import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledOrderTrackingWrapper = styled.div``;

export const FlexSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 780px) {
    display: block;
  }
`;

export const FlexSectionItem = styled.div`
  flex-basis: 50%;

  @media (min-width: 780px) {
    &:nth-child(1) {
      margin-right: ${styles.spacing.largest}px;
    }
  }
`;

export const CustomFlexSectionItem = styled(FlexSectionItem)`
  flex-basis: ${({ flexBasisPercentages }) =>
    flexBasisPercentages + "%" ?? "fill"};
`;

export const LogoWrapper = styled.div``;
