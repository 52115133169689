import { GLOBAL_ACTIONS_TYPES } from "./static";

export const saveData = (data) => {
  return { payload: { data }, type: GLOBAL_ACTIONS_TYPES.SAVE_DATA };
};

export const startLoading = () => {
  return { payload: {}, type: GLOBAL_ACTIONS_TYPES.START_LOADING };
};

export const finishLoading = (isLoaded) => {
  return { payload: { isLoaded }, type: GLOBAL_ACTIONS_TYPES.FINISH_LOADING };
};

export const setError = () => {
  return { payload: {}, type: GLOBAL_ACTIONS_TYPES.SET_ERROR };
};

export const resetData = () => {
  return { payload: {}, type: GLOBAL_ACTIONS_TYPES.RESET_DATA };
};

export const resetError = () => {
  return { payload: {}, type: GLOBAL_ACTIONS_TYPES.RESET_ERROR };
};
