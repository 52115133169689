import { StyledAdditionalOrderDataWrapper } from "./AdditionalOrderData.styled";
import { useContext } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import {
  getOrderData,
  getOrderTrakingData,
} from "../../state/global/selectors";
import Section from "../../layout/Section";
import RowsWrapper from "../RowsWrapper";

export default function AdditionalOrderData() {
  const { state } = useContext(globalStateContext);
  const {
    paymentForm,
    paymentStatus,
    deliveryMethod,
    additionalComments,
  } = getOrderData(state);

  const { provider, url, waybill } = getOrderTrakingData(state);

  const checkShouldDisplayLink = () => provider && url && waybill;

  const getLinkComponentObject = (content, url) => ({
    content,
    component: checkShouldDisplayLink() ? (
      <a target="blank" href={url}>
        {content}
      </a>
    ) : null,
  });

  const dataRows = Object.freeze({
    ["Forma płatności"]: paymentForm,
    ["Status płatności"]: paymentStatus,
    ["Dostawca"]: getLinkComponentObject(provider, url),
    ["Sposób dostawy"]: deliveryMethod,
    ["Nr. listu przewozowego"]: getLinkComponentObject(waybill, url),
    ["Uwagi do zamówienia"]: additionalComments,
  });

  return (
    <StyledAdditionalOrderDataWrapper>
      <Section withBorder label="Dodatkowe informacje">
        <RowsWrapper dataRows={dataRows} />
      </Section>
    </StyledAdditionalOrderDataWrapper>
  );
}
