import styled from "styled-components";
import { styles } from "../../GlobalStyle";
import Text from "../Text";

export const StyledButton = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButtonWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  bottom: ${styles.spacing.normal}px;
  right: ${styles.spacing.largest * 2}px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  z-index: 1000;
  background: linear-gradient(
    48deg,
    rgb(0, 0, 99) 30%,
    rgb(0, 18, 242) 100%
  );
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  @media (min-width: 640px) {
    &:hover {
      transform: translateY(-2px);
      opacity: 1;
    }
  }
  @media (max-width: 640px) {
    right: 50%;
    transform: translateX(50%);
    &:hover {
      transform: translate(50%, -2px);
      opacity: 1;
    }
  }
`;

export const ButtonText = styled(Text)`
  line-height: 1;
`;
