import { useParams, Link } from "react-router-dom";
import Section from "../../layout/Section";
import Text from "../../components/Text";
import Logo from "../../components/Logo";
import logoImg from "../../static/img/logo/XSale_nowe_logo_czarny.png";
import {
  NotFoundWrapper,
  SectionWrapper,
  ContentWrapper,
} from "./NotFoundView.styled";

export default function NotFoundView() {
  const { fguid } = useParams();
  return (
    <NotFoundWrapper>
      <Logo img={logoImg} centered displayAsLink />
      <SectionWrapper>
        <Section withBorder label="Nie znaleźliśmy Twojego zamówienia">
          <ContentWrapper>
            <Text>
            Niestety, nie możemy znaleźć zamówienia o kodzie (
              <Text bold displayAs="span">
                {fguid}
              </Text>
              )
            </Text>
          </ContentWrapper>
          <ContentWrapper>
            <Text>
            Sprawdź, czy podany kod FGUID jest poprawny i <Link to={"/"}>spróbuj ponownie</Link>
              .
            </Text>
          </ContentWrapper>
          <ContentWrapper>
          <Text bold displayAs="span">Pamiętaj, że wyświetlamy tylko zamówienia z ostatnich 30 dni.</Text>
          </ContentWrapper>
        </Section>
      </SectionWrapper>
    </NotFoundWrapper>
  );
}
