import MainRouter from "./containers/routers/MainRouter";
import GlobalStateProvider from "./containers/contexts/GlobalState";
import { GLOBAL_INITIAL_STATE } from "./state/global/static";

function App() {
  return (
    <div className="App">
      <GlobalStateProvider initial={GLOBAL_INITIAL_STATE}>
        <MainRouter />
      </GlobalStateProvider>
    </div>
  );
}

export default App;
