export const providersUrlsGetters = Object.freeze({
  ["dpd"]: (waybill) =>
    `https://tracktrace.dpd.com.pl/parcelDetails?p1=${waybill}`,
  ["inpost"]: (waybill) =>
    `https://inpost.pl/sledzenie-przesylek?number=${waybill}`,
  ["dhl"]: (waybill) =>
    `https://www.dhl.com/pl-pl/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${waybill}`,
  ["fedex"]: (waybill) =>
    `https://www.fedex.com/fedextrack/?tracknumbers=${waybill}&cntry_code=pl`,
  ["poczta polska"]: (waybill) =>
    `https://emonitoring.poczta-polska.pl/?numer=${waybill}`,
  ["świat przesyłek"]: (waybill) => `https://swiatprzesylek.pl/tt/${waybill}`,
  ["Paczka w Ruchu"]: (waybill) =>
    `https://www.paczkawruchu.pl/sledz-paczke/?numer=${waybill}`,
  ["europaczka"]: (waybill) =>
    `http://83.144.109.138/xsc/XTrace/Default.aspx?Shipment.RefNo=${waybill}`,
  ["geis"]: (waybill) =>
    `https://www.geis.pl/pl/sledzenie-przesylki?p=${waybill}`,
  ["kex"]: (waybill) => `https://www.epaka.pl/sledzenie-przesylek/${waybill}`,
  ["gls-cz"]: (waybill) =>
    `https://gls-group.eu/CZ/cs/sledovani-zasilek.html?match=${waybill}`,
  ["gls-hu"]: (waybill) =>
    `https://gls-group.eu/HU/hu/csomagkovetes.html?match=${waybill}`,
  ["gls-pl"]: (waybill) =>
    `https://gls-group.eu/PL/pl/sledzenie-paczek.html?match=${waybill}`,
  ["gls-ro"]: (waybill) =>
    `https://gls-group.eu/RO/ro/urmarire-colet.html?match=${waybill}`,
  ["gls-sk"]: (waybill) =>
    `https://gls-group.eu/SK/sk/sledovanie-zasielok.html?match=${waybill}`,
  ["royal mail"]: (waybill) =>
    `https://royalmail.com/track-your-item#/tracking-results/${waybill}`,
  ["dpd-de"]: (waybill) =>
    `https://www.dpd.com/de/de/empfangen/sendungsverfolgung-und-live-tracking`,
  ["sky-logistycs"]: (waybill) => `https://www.skylogisticspl.com/pl/tracking`,
});

export const getProviderUrl = (providerName, waybill) =>
  waybill && providersUrlsGetters[providerName?.toLowerCase()]?.(waybill);
