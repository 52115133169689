import { useEffect, useState, useMemo, useCallback } from "react";
import { StyledFguidInputPart, StyledDash } from "./FguidInput.styled";

export default function InputPart({
  value,
  maxLength,
  onChange,
  addInputRef,
  isLast,
}) {
  const [inputValue, setInputValue] = useState(value);
  const regexp = useMemo(() => new RegExp(`^(\\d||\\w){0,${maxLength}}$`), [
    maxLength,
  ]);

  const handleInputChange = useCallback(
    (e) =>
      regexp.test(e.target.value) &&
      setInputValue(e.target.value?.toUpperCase()),
    [regexp]
  );

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  const placeholder = useMemo(() => Array(maxLength).fill("X").join(""), [
    maxLength,
  ]);

  return (
    <>
      {" "}
      <StyledFguidInputPart
        ref={addInputRef}
        type="text"
        maxLength={maxLength}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />{" "}
      {!isLast && <StyledDash>-</StyledDash>}
    </>
  );
}
