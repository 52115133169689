import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledFooterWrapper = styled.div`
  bottom: ${styles.spacing.small}px;
  left: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${styles.spacing.large}px;
`;
