import { StyledSection, StyledLabel } from "./Section.styled";
import HeaderText from "../../components/HeaderText";
import { styles } from "../../GlobalStyle";

export default function Section({
  children,
  label,
  withBorder,
  isDark,
  flexBasis,
  backgroundColor,
  padding,
  margin,
  prior,
}) {
  return (
    <StyledSection
      flexBasis={flexBasis}
      isDark={isDark}
      withBorder={withBorder}
      backgroundColor={backgroundColor}
      prior={prior}
      padding={padding}
      margin={margin}
    >
      {label && (
        <StyledLabel>
          <HeaderText
            color={styles.colors.secondary}
            bold
            size={styles.fontSizes.large}
            align="center"
          >
            {label}
          </HeaderText>
        </StyledLabel>
      )}
      {children}
    </StyledSection>
  );
}
