import {
  StyledOrderItemsListWrapper,
  StyledOrderItemsList,
  StyledOrderItemsListHeader,
  StyledHeaderLabel,
  StyledListContainer,
  StyledPriceSum,
} from "./OrderItemsList.styled";
import { useContext, useCallback, useMemo } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import { getOrderItems } from "../../state/global/selectors";
import OrderItem from "../OrderItem";
import Section from "../../layout/Section";
import Text from "../Text";
import { styles } from "../../GlobalStyle";
import useMediaQuery from "../../hooks/useMediaQuery";
import { labels } from "../../static/productHeaderLabels";

const headerLabelsList = [
  labels.img,
  labels.name,
  labels.code,
  labels.price,
  labels.quantity,
];

export default function OrderItemsList() {
  const { state } = useContext(globalStateContext);
  const orderItems = getOrderItems(state);
  const mediaIsMatched = useMediaQuery("(min-width: 600px)");

  const renderItems = useCallback(
    (orderItems) =>
      orderItems.map((orderItemData, index) => {
        const { Images, PriceGross, Quantity, Code, Name } =
          orderItemData || {};
        const ImgSrc = Array.isArray(Images) ? Images[0]?.Original?.Url : "";
        return (
          <OrderItem
            key={index}
            data={{
              [labels.img]: ImgSrc,
              [labels.name]: Name,
              [labels.code]: Code,
              [labels.price]: `${parseFloat(PriceGross).toFixed(2)}zł`,
              [labels.quantity]: Quantity,
            }}
            isOdd={!!((index + 1) % 2)}
          />
        );
      }),
    []
  );

  const renderLabels = useCallback(
    (labels) =>
      labels.map((label, index, labels) => (
        <StyledHeaderLabel key={index} quantity={labels.length}>
          <Text align="left" bold>
            {label}
          </Text>
        </StyledHeaderLabel>
      )),
    []
  );

  const summedPrices = useMemo(
    () =>
      orderItems
        .reduce(
          (pricesSum, { Quantity, PriceGross }) =>
            (pricesSum += parseFloat(Quantity * PriceGross)),
          0
        )
        ?.toFixed(2) ?? 0,
    [orderItems]
  );

  return (
    <StyledOrderItemsListWrapper>
      <Section withBorder label="Zamówione przedmioty">
        <StyledListContainer>
          <StyledOrderItemsListHeader>
            {mediaIsMatched && renderLabels(headerLabelsList)}
          </StyledOrderItemsListHeader>
          <StyledOrderItemsList>{renderItems(orderItems)}</StyledOrderItemsList>
        </StyledListContainer>
        <Section margin={0} padding={0}>
          <StyledPriceSum>
            <Text>
              Wartość końcowa:{" "}
              <Text displayAs="span" bold color={styles.colors.primary}>
                {summedPrices}zł
              </Text>
            </Text>
          </StyledPriceSum>
        </Section>
      </Section>
    </StyledOrderItemsListWrapper>
  );
}

OrderItemsList.propTypes = {};

OrderItemsList.defaultProps = {};
