import { useContext, useMemo } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
export default function WithPersonalData({ children, selector }) {
  const { state } = useContext(globalStateContext);

  const {
    firstName,
    lastName,
    thirdName,
    nipNumber,
    address,
    zipCode,
    locality,
    phoneNumber,
    emailAddress,
    country,
  } = selector(state) || {};

  const combineNames = (...names) =>
    names?.reduce(
      (namesEntries, name) =>
        name ? [...namesEntries, [null, name]] : namesEntries,
      []
    );

  const dataRows = useMemo(
    () =>
      Object.freeze({
        ["Nazwa"]: combineNames(firstName, lastName, thirdName),
        ["NIP"]: nipNumber,
        ["Adres"]: address,
        ["Kod pocztowy"]: zipCode,
        ["Miejscowość"]: locality,
        ["Kraj"]: country,
        ["Numer telefonu"]: phoneNumber,
        ["Adres email"]: emailAddress,
      }),
    [state]
  );

  return <>{children(dataRows)}</>;
}
