const types = new Map()
  .set("Nowe", 1)
  .set("Zaimportowano do ERP", 2)
  .set("Przetwarzane", 2)
  .set("Do obsługi ręcznej", 2)
  .set("W trakcie realizacji", 3)
  .set("Przygotowywane do wysyłki", 3)
  .set("Gotowe do wysyłki", 4)
  .set("Wysłane", 5)
  .set("Zrealizowane", 6)
  .set("Anulowane", 6)
  .set("Wstrzymane", 3)
  .set("Interwencja", 3)
  .set("Zrealizowane", 6)
  .set("Oczekujące na płatność", 2)
  .set("Płatność zweryfikowana", 2)
  .set("Koszyk-rekomendacja", 1)
  .set("Koszyk-niezatwierdzony", 1)
  .set("Koszyk-anulowany", 6)
  .set("Niezatwierdzone (Allegro)", 1)
  .set("Niezatwierdzone", 1);

const orderStatuses = { types, tresholdsQuantity: 6 };

export default orderStatuses;
