import { StyledMainWrapper } from "./Main.styled";
import Footer from "../Footer";

export default function MainWrapper({ children }) {
  return (
    <StyledMainWrapper>
      {children}
      <Footer />
    </StyledMainWrapper>
  );
}
