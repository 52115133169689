import { createGlobalStyle } from "styled-components";
import MangericaBold from "./static/fonts/Mangerica-Bold.otf";
import MangericaLight from "./static/fonts/Mangerica-Light.otf";
import MangericaRegular from "./static/fonts/Mangerica-Regular.otf";
import styledLeaflet from "styled-leaflet";

export const styles = Object.freeze({
  colors: {
    primary: "#0f4fd9",
    secondary: "#fd7702",
    black: "#000000",
    white: "#ffffff",
    gray: "#b2b2b2",
    text: "#252321",
    lightGray: "#f1f1f1",
    accent: "#6d7d9e",
    blue: '#'
  },

  spacing: {
    verySmall: 6,
    small: 14,
    normal: 20,
    large: 26,
    larger: 32,
    largest: 44,
    huge: 58,
  },

  fonts: {
    main: {
      regular: "Mangetica Regular",
      bold: "Mangetica Bold",
      light: "Mangetica Light",
    },
  },

  fontSizes: {
    verySmall: 8,
    small: 12,
    normal: 16,
    large: 24,
    larger: 32,
    largest: 48,
    huge: 64,
  },
});

export default createGlobalStyle`

  ${styledLeaflet}

  @font-face {
    font-family: ${styles.fonts.main.regular};
    src: url(${MangericaRegular}) format('opentype');
  }

  @font-face {
    font-family: ${styles.fonts.main.bold};
    src: url(${MangericaBold}) format('opentype');
  }

  @font-face {
    font-family: ${styles.fonts.main.light};
    src: url(${MangericaLight}) format('opentype');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    color: ${styles.colors.text};
    font-family: ${styles.fonts.main.light};
    line-height: 1;
    font-size: ${styles.fontSizes.normal}px;
  }

  a {
    color: ${styles.colors.primary};
    text-decoration: none;
  }

  ol, ul {
	  list-style: none;
  }

  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: ${styles.fontSizes.normal}px;
  }

  button, input, select, textarea {
    border: none;
    margin: 0;
    padding:0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    outline: none;
    box-shadow: none
  }

`;
