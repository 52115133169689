import { GLOBAL_STATE_PROPS } from "./static";
import { getProviderUrl } from "../../static/ProvidersUrls";

export const getOrderItems = (state) => state[GLOBAL_STATE_PROPS.ORDER_ITEMS];

export const getFguid = (state) => state[GLOBAL_STATE_PROPS.FGUID];

export const getBuyerData = (state) => state[GLOBAL_STATE_PROPS.BUYER_DATA];

export const getLogoUrl = (state) => state[GLOBAL_STATE_PROPS.LOGO_URL];

export const getRecipientData = (state) =>
  state[GLOBAL_STATE_PROPS.RECIPIENT_DATA];

export const getOrderData = (state) => state[GLOBAL_STATE_PROPS.ORDER_DATA];

export const checkIsLoaded = (state) => state[GLOBAL_STATE_PROPS.IS_LOADED];

export const checkIsLoading = (state) => state[GLOBAL_STATE_PROPS.IS_LOADING];

export const checkHasError = (state) => state[GLOBAL_STATE_PROPS.HAS_ERROR];

export const getProvider = (state) =>
  state[GLOBAL_STATE_PROPS.ORDER_DATA]?.provider;

export const getOrderTrakingData = (state) => {
  const provider = getProvider(state),
    waybill = getOrderData(state)?.waybillNumber;
  return {
    provider,
    waybill,
    url: getProviderUrl(provider, waybill),
  };
};
