import styled from "styled-components";
import { styles } from "../../GlobalStyle";
import myImg from './../../static/img/logo/tlo.PNG'

export const StyledFgiudFormWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 620px) {
    width: 100%;
  }
`;

export const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const StyledFguidInputContentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${styles.spacing.normal}px;
  @media (max-width: 440px) {
    padding: ${styles.spacing.large}px ${styles.spacing.verySmall}px;
  }
`;

export const StyledInputLabel = styled.label``;

export const StyledConfirmButton = styled.button`
  margin: ${styles.spacing.normal}px 0;
  padding: ${styles.spacing.small}px ${styles.spacing.normal}px;
  min-width: 120px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${styles.colors.white};
  background-color: ${styles.colors.secondary};
  transition: 0.2s;
  transform-origin: center;
  transform: translateY(0);
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 0.8)};
  &:hover {
    transform: ${({ disabled }) =>
      disabled ? "translateY(0)" : "translateY(-2px)"};
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  }
  @media (max-width: 440px) {
    padding: ${styles.spacing.verySmall}px ${styles.spacing.normal}px;
    margin: ${styles.spacing.verySmall}px 0;
  }
`;
