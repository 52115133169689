import PropTypes from "prop-types";
import { useContext } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import { getOrderTrakingData } from "../../state/global/selectors";
import {
  StyledButton,
  StyledButtonWrapper,
  ButtonText,
} from "./ConsignmentTrackingButton.styled";
import { styles } from "../../GlobalStyle";

export default function ConsignmentTrackingButton() {
  const { state } = useContext(globalStateContext);
  const { url, waybill } = getOrderTrakingData(state);

  const checkShouldDisplay = () => url && waybill;

  return (
    <>
      {checkShouldDisplay() && (
        <StyledButtonWrapper>
          <StyledButton href={url} target="blank">
            <ButtonText bright size={styles.fontSizes.normal} align="center">
              Śledź paczkę
            </ButtonText>
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </>
  );
}

ConsignmentTrackingButton.propTypes = {
  url: PropTypes.string.isRequired,
};

ConsignmentTrackingButton.defaultProps = {
  url: "",
};
