import { useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import { getOrderData, getRecipientData } from "../../state/global/selectors";
import { StyledMapWrapper } from "./Map.styled";
import Section from "../../layout/Section";
import { styles } from "../../GlobalStyle";
import Text from "../Text";

import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [17, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Map() {
  const { state } = useContext(globalStateContext);
  const {
    coordinates: {
      to: [lat, lon],
    },
  } = getOrderData(state);
  const { address, locality, zipCode, country } = getRecipientData(state);

  const localityDataToDisplay = `${address} ${
    zipCode ?? ""
  } ${locality} ${country}`;

  const checkHasCoordinates = () => (lat || lat === 0) && (lon || lon === 0);

  return (
    <StyledMapWrapper>
      <Section withBorder label="Mapa" padding={styles.spacing.normal}>
        {checkHasCoordinates() ? (
          <MapContainer
            style={{ height: `${styles.spacing.larger * 12}px` }}
            center={[lat, lon]}
            zoom={13}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[lat, lon]}>
              <Popup>
                Lokalizacja odbiorcy zamówienia: <br />
                {localityDataToDisplay}
              </Popup>
            </Marker>
          </MapContainer>
        ) : (
          <Text>
            Adres:{" "}
            <Text bold displayAs="span">
              {localityDataToDisplay}
            </Text>{" "}
            prawdopodobnie ma błędny format lub nie istnieje.
          </Text>
        )}
      </Section>
    </StyledMapWrapper>
  );
}

Map.propTypes = {};

Map.defaultProps = {};
