import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledOrderItemWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media(max-width: 600px){
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: ${styles.spacing.large*2}px 0;
     &:nth-child(1) {
        padding-top: 0;
      }
      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    &::after{
      content:"";
      position: absolute;
      bottom: 0;
      width: 90%;
      height: 2px;
      background: ${styles.colors.lightGray};
    }
  }
`;

export const StyledItemContentWrapper = styled.div`
  flex-grow: 1;
  flex-basis: ${({ quantity }) => 100 / quantity}%;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: flex-start;
  padding: ${styles.spacing.normal}px ${styles.spacing.normal}px;
  background-color: ${({ isOdd }) =>
    isOdd ? styles.colors.lightGray : styles.colors.white};
  &:nth-last-child(1) {
    min-width: 120px;
  }
    @media(max-width: 600px){
          background-color: ${styles.colors.white};
      &:nth-last-child(1) {
        min-width: auto;
        flex-basis: 40%;
        order: 3
      }
      &:nth-last-child(2) {
        min-width: auto;
        flex-basis: 55%;
        order: 4
      }
      &:nth-child(1) {
        flex-basis: 40%;
        min-width: auto;
      }
      &:nth-child(2) {
        flex-basis: 55%;
        min-width: 160px;
      }
      &:nth-child(3) {
        flex-basis: 100%;
        min-width: auto;
        flex-direction: row;
        flex-wrap: wrap;
        order: 5
      }
      justify-content: flex-start;
      align-items: flex-start;
    }
`;



export const ProduktImg = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const ImgWrapper = styled.div`
  position: relative;
  width: 120px;
  @media(max-width: 300px){
    width: 140px
  }
`;
