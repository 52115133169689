import { StyledFguidInputWrapper } from "./FguidInput.styled";
import InputPart from "./InputPart";

export default function FguidInput({
  fguidChunks,
  handleInputChange,
  addInputRef,
}) {
  return (
    <StyledFguidInputWrapper>
      {fguidChunks.map((chunk, index) => (
        <InputPart
          key={index}
          onChange={handleInputChange(index)}
          value={chunk.value}
          maxLength={chunk.maxLength}
          addInputRef={addInputRef(index)}
          isLast={index === fguidChunks.length - 1}
        />
      ))}
    </StyledFguidInputWrapper>
  );
}
