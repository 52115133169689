import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const NotFoundWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (max-width: 780px) {
    width: 100%;
  }
`;

export const SectionWrapper = styled.div`
  margin: ${styles.spacing.largest}px auto;
`;

export const ContentWrapper = styled.div`
  padding: ${styles.spacing.verySmall}px 0;
`;
