import PropTypes from "prop-types";
import Section from "../../layout/Section";
import Text from "../../components/Text";
import Logo from "../../components/Logo";
import logoImg from "../../static/img/logo/XSale_nowe_logo_czarny.png";
import { styles } from "../../GlobalStyle";
import FguidInput from "../../components/FguidInput.js";

import {
  StyledConfirmButton,
  StyledFgiudFormWrapper,
  StyledInputLabel,
  StyledFguidInputContentWrapper,
  SectionWrapper,
} from "./FguidFormView.styled";

export default function FguidInputView({
  redirect,
  handleInputChange,
  canSubmit,
  maxLength,
  dashesQuantity,
  fguidChunks,
  addInputRef,
  handleInputFocus,
  currentFguidLength,
}) {
  return (
    <StyledFgiudFormWrapper>
      <Logo centered img={logoImg} width={300} displayAsLink />
      <SectionWrapper>
        <Section padding={15} label="Podaj kod zamówienia" withBorder>
          <StyledFguidInputContentWrapper onSubmit={redirect}>
            <FguidInput
              addInputRef={addInputRef}
              fguidChunks={fguidChunks}
              handleInputChange={handleInputChange}
              handleInputFocus={handleInputFocus}
            />
            <Text
              color={canSubmit ? styles.colors.primary : styles.colors.accent}
            >
              {currentFguidLength}/{maxLength}
            </Text>
            <StyledConfirmButton disabled={!canSubmit}>OK</StyledConfirmButton>
          </StyledFguidInputContentWrapper>
        </Section>
      </SectionWrapper>
    </StyledFgiudFormWrapper>
  );
}

FguidInputView.propTypes = {
  inputId: PropTypes.string.isRequired,
  fguidValue: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
};

FguidInputView.defaultProps = {
  redirect: () => {},
  handleInputChange: () => {},
  fguidValue: "",
};
