import { useContext, useCallback, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { globalStateContext } from "../contexts/GlobalState";
import { paths } from "../routers/MainRouter";

import {
  saveData,
  startLoading,
  finishLoading,
  resetError,
  resetData,
} from "../../state/global/actions";
import {
  checkHasError,
  checkIsLoading,
  checkIsLoaded,
  getLogoUrl,
} from "../../state/global/selectors";

import xSaleService from "../../services/xSaleApi";
import OrderTrackingView from "../../views/OrderTrackingView";
import LoadingIndicator from "../../components/LoadingIndicator";

export default function OrderTracking() {
  const { fguid } = useParams();

  const { state, dispatch } = useContext(globalStateContext),
    isLoading = checkIsLoading(state),
    isLoaded = checkIsLoaded(state),
    hasError = checkHasError(state),
    logoUrl = getLogoUrl(state);

  const handleGettingOrderData = useCallback(async (fguid) => {
    dispatch(startLoading());

    try {
      const data = await xSaleService.getOrderData(fguid);
      if (data.error) {
        throw Error("error");
      }
      dispatch(saveData(data));
      dispatch(finishLoading(true));
    } catch (err) {
      console.error(err);
      dispatch(finishLoading(false));
    }
  }, []);

  useEffect(() => {
    handleGettingOrderData(fguid);
    return () => {
      dispatch(resetError());
      dispatch(resetData());
    };
  }, [fguid]);

  const render = () => {
    if (isLoading) {
      return <LoadingIndicator isLoading={isLoading} />;
    }

    if (isLoaded) {
      return <OrderTrackingView logoUrl={logoUrl} />;
    }

    if (!isLoaded && hasError) {
      return <Redirect to={paths.notFound(fguid)} />;
    }

    return null;
  };

  return render();
}
