import { StyledDataRow } from "./RowsWrapper.styled";
import Text from "../Text";
export default function RowsWrapper({
  dataRows,
  WrapperComponent,
  ContentComponent,
}) {
  const toEntries = (object) =>
    Array.isArray(object)
      ? object
      : Object.entries(
          typeof object === "object" && object !== null ? object : {}
        );

  const validValue = (value) => {
    return (
      ((value || value === 0) &&
        value?.toLowerCase?.() !== "null" &&
        value?.constructor !== Object) ||
      (value?.content && value?.component)
    );
  };

  const renderRows = (dataRows) =>
    toEntries(dataRows).map(([label, value], index) => (
      <>
        {validValue(value) && (
          <StyledDataRow key={index}>
            {label ? (
              <Text bold>
                {label}:{" "}
                <Text displayAs={Array.isArray(value) ? "p" : "span"}>
                  {Array.isArray(value)
                    ? renderRows(value)
                    : value?.component || value}
                </Text>
              </Text>
            ) : (
              <Text>{value}</Text>
            )}
          </StyledDataRow>
        )}
      </>
    ));
  return renderRows(dataRows, WrapperComponent, ContentComponent);
}
