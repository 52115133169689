import { StyledRecipientDataWrapper } from "./RecipientData.styled";
import { getRecipientData } from "../../state/global/selectors";
import Section from "../../layout/Section";
import RowsWrapper from "../RowsWrapper";
import WithPersonalData from "../../containers/WithPersonalData";

export default function RecipientData() {
  return (
    <WithPersonalData selector={getRecipientData}>
      {(dataRows) => (
        <StyledRecipientDataWrapper>
          <Section withBorder label="Odbiorca">
            <RowsWrapper dataRows={dataRows} />
          </Section>
        </StyledRecipientDataWrapper>
      )}
    </WithPersonalData>
  );
}
