import { useReducer, createContext } from "react";
import globalReducer from "../../state/global/reducer";

export const globalStateContext = createContext();

export default function GlobalStateProvider({ children, initial }) {
  const [state, dispatch] = useReducer(globalReducer, initial || {});
  const stateValue = { state, dispatch };

  return (
    <globalStateContext.Provider value={stateValue}>
      {children}
    </globalStateContext.Provider>
  );
}
