import { Fragment } from "react";
import PropTypes from "prop-types";
import { StyledLogo, StyledLogoWrapper } from "./Logo.styled";
import { Link, useParams } from "react-router-dom";
export default function Logo({
  img,
  centered,
  width,
  scaled,
  marginEnable,
  displayAsLink,
}) {
  const { fguid } = useParams();
  const Wrapper = displayAsLink ? Link : Fragment;
  return (
    <Wrapper
      to={{
        pathname: "/",
        state: { fguid },
      }}
    >
      <StyledLogoWrapper
        marginEnable={marginEnable}
        centered={centered}
        width={width}
        scaled={scaled}
      >
        <StyledLogo src={img} />
      </StyledLogoWrapper>
    </Wrapper>
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  centered: PropTypes.bool,
  img: PropTypes.string,
  scaled: PropTypes.bool,
  marginEnable: PropTypes.bool,
  displayAsLink: PropTypes.bool,
};

Logo.defaultProps = {
  centered: false,
  img: "",
  scaled: false,
  marginEnable: false,
  displayAsLink: false,
};
