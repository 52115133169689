import { useCallback, useEffect, useRef ,useState} from "react";

export default function(mediaQueryString){
    const mediaQueryRef = useRef(window.matchMedia(mediaQueryString));
    const [mediaIsMatched, setMediaIsMatched] = useState(mediaQueryRef.current.matches)

  const handleMatches = useCallback((e)=>{
     setMediaIsMatched(e.matches)
   },[])

  useEffect(()=>{
     mediaQueryRef.current?.addEventListener('change', handleMatches)
     return () => mediaQueryRef.current?.removeEventListener('change',handleMatches)
  },[])

  return mediaIsMatched
}