import orderStatuses from "../../../static/orderStatuses";
import {
  StyledOrderStatusBar,
  StyledOrderStatusBarWrapper,
  ProgressRatioWrapper,
  StatusBarText,
} from "./StatusBar.styled";

import { styles } from "../../../GlobalStyle";

export default function OrderStatusBar({ status }) {
  const { types: statusTypes, tresholdsQuantity } = orderStatuses;
  const orderTreshold = statusTypes.get(status) ?? 0;

  return (
    <StyledOrderStatusBarWrapper>
      <StatusBarText side="left" color="white">
        Postęp realizacji zamówienia
      </StatusBarText>
      <StyledOrderStatusBar progressRatio={orderTreshold / tresholdsQuantity}>
        <ProgressRatioWrapper />
      </StyledOrderStatusBar>
      <StatusBarText bold side="right" color="white">
        krok {orderTreshold} z {tresholdsQuantity}
      </StatusBarText>
    </StyledOrderStatusBarWrapper>
  );
}
