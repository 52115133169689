import { useContext } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import { getOrderData } from "../../state/global/selectors";
import {
  StyledOrderBasicDataWrapper,
  StyledDataRow,
} from "./OrderBasicData.styled";
import StatusBar from "./StatusBar";
import Section from "../../layout/Section";
import Text from "../Text";

export default function OrderBasicData() {
  const { state } = useContext(globalStateContext);
  const { number, date, status } = getOrderData(state);
  return (
    <StyledOrderBasicDataWrapper>
      <Section orderData withBorder label="Dane zamówienia">
        <StyledDataRow>
          <Text bold>
            Numer zamówienia: <Text displayAs="span">{number}</Text>
          </Text>
        </StyledDataRow>
        <StyledDataRow>
          <Text bold>
            Data zamówienia: <Text displayAs="span">{date}</Text>
          </Text>
        </StyledDataRow>
        <StyledDataRow>
          <Text bold>
            Status: <Text displayAs="span">{status}</Text>
          </Text>
        </StyledDataRow>
        <StyledDataRow>
          <StatusBar status={status} />
        </StyledDataRow>
      </Section>
    </StyledOrderBasicDataWrapper>
  );
}
