import styled, { css } from 'styled-components'
import { styles } from "../../GlobalStyle";

export const StyledSection = styled.section`
  position: relative;
  background-color: ${({ isDark, backgroundColor }) =>
    backgroundColor
      ? backgroundColor
      : isDark
      ? styles.colors.text
      : styles.colors.white};
  flex-basis: ${({ flexBasis }) => flexBasis || "content"};
  border: ${({ withBorder, prior }) => (withBorder || prior ? 1 : 0)}px solid white;
  padding: ${({ padding }) => padding ?? styles.spacing.normal}px;
  margin: ${({ margin }) => margin ?? styles.spacing.normal}px 0;
  border-radius: 15px ;
  box-shadow: 0 0 10px 5px #f2f2f2;
  @media (max-width: 780px) {
    margin: ${({ margin }) => margin ?? styles.spacing.largest}px 0;
  }
  @media (max-width: 460px) {
    margin: ${({ margin }) => margin ?? styles.spacing.larger}px 0;
  }

  ${props => props.orderData && css`
  border: 1px solid white;
  `}
`;

export const StyledLabel = styled.div`
  display: flex;
  padding: 0 0 10px 0;
  background-color: ${styles.colors.white};
`;
