import { StyledBuyerDataWrapper } from "./BuyerData.styled";
import { getBuyerData } from "../../state/global/selectors";
import Section from "../../layout/Section";
import RowsWrapper from "../RowsWrapper";
import WithPersonalData from "../../containers/WithPersonalData";
export default function BuyerData() {
  return (
    <WithPersonalData selector={getBuyerData}>
      {(dataRows) => (
        <StyledBuyerDataWrapper>
          <Section withBorder label="Kupujący">
            <RowsWrapper dataRows={dataRows} />
          </Section>
        </StyledBuyerDataWrapper>
      )}
    </WithPersonalData>
  );
}
