import { StyledFooterWrapper } from "./Footer.styled";
import { useContext } from "react";
import { globalStateContext } from "../../containers/contexts/GlobalState";
import { checkIsLoading } from "../../state/global/selectors";
import Text from "../../components/Text";
import Logo from "../../components/Logo";
import logoImg from "../../static/img/logo/XSale_nowe_logo_czarny.png";

export default function Footer() {
  const { state } = useContext(globalStateContext);
  const isLoading = checkIsLoading(state);
  return (
    <>
      {!isLoading && (
        <StyledFooterWrapper>
          <Text align="center" size={12}>
            Strona wygenerowana automatycznie przez xSale - narzędzie do
            automatyzacji eCommerce
          </Text>
          <Logo img={logoImg} width={150} />
        </StyledFooterWrapper>
      )}
    </>
  );
}
