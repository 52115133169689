import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledOrderItemsListWrapper = styled.div``;

export const StyledOrderItemsList = styled.ol`
  flex-basis: max-content;
`;

export const StyledListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${styles.spacing.normal}px 0;
  @media (max-width: 800px) {
    overflow-x: scroll;
  }
`;

export const StyledOrderItemsListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeaderLabel = styled.div`
  flex-basis: ${({ quantity }) => 100 / quantity}%;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${styles.spacing.small}px ${styles.spacing.normal}px;
  &:nth-last-child(1) {
    min-width: 120px;
  }
`;

export const StyledPriceSum = styled.div`
  display: flex;
  padding: ${styles.spacing.small}px ${styles.spacing.normal}px;
  align-items: center;
  justify-content: flex-end;
`;
