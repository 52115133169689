import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import FguidForm from "../../containers/FguidForm";
import OrderTracking from "../../containers/OrderTracking";
import NotFoundView from "../../views/NotFoundView";
import MainWrapper from "../../layout/Main";

export const paths = Object.freeze({
  orderTracking: (param = ":fguid") => `/${param}`,
  notFound: (param = ":fguid") => `${paths.orderTracking(param)}/not-found`,
});

export default function MainRouter() {
  return (
    <Router>
      <MainWrapper>
        <Switch>
          <Route path="/" exact>
            <HashRouter basename="">
              <Switch>
                <Route path="/" exact>
                  <FguidForm />
                </Route>
                <Route path={paths.orderTracking()} exact>
                  <OrderTracking />
                </Route>
                <Route path={paths.notFound()}>
                  <NotFoundView />
                </Route>
              </Switch>
            </HashRouter>
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </MainWrapper>
    </Router>
  );
}
