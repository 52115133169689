import styled, { css } from "styled-components";
import Text from "../../Text";
import { styles } from "../../../GlobalStyle";

const positionSideMixin = css`
  ${({ side, position = 0, unit = "px" }) => `${side}:${position}${unit};`}
`;

export const StyledOrderStatusBarWrapper = styled.div`
  position: relative;
  height: ${styles.spacing.larger}px;
  width: 100%;
  border-radius: 5px;
  background-color: ${styles.colors.gray};
  margin-top: ${styles.spacing.verySmall}px;
`;

export const StyledOrderStatusBar = styled.div`
  height: 100%;
  width: ${({ progressRatio }) => 100 * progressRatio}%;
  background: linear-gradient(
    48deg,
    rgb(0, 0, 99) 30%,
    rgb(0, 18, 242) 100%
  );
  position: absolute;
  top: 0;
  border-radius: 5px;
`;

export const ProgressRatioWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

export const StatusBarText = styled(Text)`
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 ${styles.spacing.small}px;
  ${positionSideMixin}

  @media (max-width: 480px) {
    font-size: 12px;
    max-width: 70%;
    padding: 0 ${styles.spacing.verySmall}px;
    line-height: 1;
  }
`;
