import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FguidFormView from "../../views/FguidFormView";
import xSaleService from "../../services/xSaleApi";
import { paths } from "../routers/MainRouter";

export default function FguidInputView() {
  const history = useHistory();

  const { state: routerState } = useLocation();

  const fguidValueRef = useRef(
    xSaleService.flatFguidToInput(routerState?.fguid ?? "")
  );

  const inputsRef = useRef({});

  const getFguidChunks = useCallback((fguidValue) => {
    return xSaleService.fguidChunksLenghts.reduce(
      ({ from, chunks }, chunkLength) => {
        const to = from + chunkLength;
        const chunk = {
          value: fguidValue.slice(from, to),
          maxLength: chunkLength,
        };
        return { from: to, chunks: [...chunks, chunk] };
      },
      { from: 0, chunks: [] }
    ).chunks;
  }, []);

  const fguidChunks = useMemo(() => getFguidChunks(fguidValueRef.current), [
    getFguidChunks,
  ]);

  const chunksRef = useRef(fguidChunks);

  const [currentFguidLength, setCurrentFguidLength] = useState(
    fguidValueRef.current.length
  );

  const [canSubmit, setCanSubmit] = useState(validFguid(currentFguidLength));

  const addInputRef = (key) => (node) => (inputsRef.current[key] = node);

  const setCurrentChunkValue = (chunkIndex, value) =>
    (chunksRef.current[chunkIndex].value = value);

  const handleFocusChange = useCallback((chunkIndex, value) => {
    let indexOffset = null;
    if (value.length === 0) {
      indexOffset = -1;
    }
    if (value.length === inputsRef.current[chunkIndex].maxLength) {
      indexOffset = 1;
    }
    indexOffset && inputsRef.current[chunkIndex + indexOffset]?.focus();
  }, []);

  const handleSetCurrentFguidLength = useCallback(() => {
    const currentFguidLength = chunksRef.current?.reduce(
      (accLength, chunk) => (accLength += chunk?.value.length ?? 0),
      0
    );

    setCurrentFguidLength(currentFguidLength);
  }, []);

  const handleInputChange = useCallback(
    (chunkIndex) => (value) => {
      setCurrentChunkValue(chunkIndex, value);
      handleFocusChange(chunkIndex, value);
      handleSetCurrentFguidLength();
    },
    []
  );

  const redirect = useCallback(
    (e) => {
      e.preventDefault();
      canSubmit &&
        history.push(
          paths.orderTracking(xSaleService.formatFguid(fguidValueRef.current))
        );
    },
    [history, canSubmit]
  );

  function validFguid(currentFguidLength) {
    if (currentFguidLength !== xSaleService.fguidReguiredLength) {
      return false;
    }

    const currentFguidValue = chunksRef.current?.reduce(
      (currentFguidValue, chunk) => (currentFguidValue += chunk?.value ?? ""),
      ""
    );

    const isValid = xSaleService.validFguid(currentFguidValue);
    if (isValid) {
      fguidValueRef.current = currentFguidValue;
    }
    return isValid;
  }

  function handleSetCanSubmit(currentFguidLength) {
    setCanSubmit(validFguid(currentFguidLength));
  }

  useEffect(() => {
    handleSetCanSubmit(currentFguidLength);
  }, [currentFguidLength]);

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  return (
    <FguidFormView
      fguidChunks={fguidChunks}
      currentFguidLength={currentFguidLength}
      redirect={redirect}
      handleInputChange={handleInputChange}
      canSubmit={canSubmit}
      dashesQuantity={xSaleService.DASH_INDEXES.length}
      maxLength={xSaleService.fguidReguiredLength}
      addInputRef={addInputRef}
    />
  );
}
