import styled from "styled-components";
import { styles } from "../../GlobalStyle";

export const StyledOrderBasicDataWrapper = styled.div`
  font-size: ${styles.fontSizes.large};
`;

export const StyledDataRow = styled.div`
  padding-top: ${styles.spacing.verySmall}px;
`;
