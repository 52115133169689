import {
  StyledOrderTrackingWrapper,
  FlexSectionWrapper,
  FlexSectionItem,
  CustomFlexSectionItem,
} from "./OrderTrackingView.styled";
import Logo from "../../components/Logo";
import logoImg from "../../static/img/logo/XSale_nowe_logo_czarny.png";
import OrderItemsList from "../../components/OrderItemsList";
import BuyerData from "../../components/BuyerData";
import AdditionalOrderData from "../../components/AdditionalOrderData";
import OrderBasicData from "../../components/OrderBasicData";
import RecipientData from "../../components/RecipientData";
import Map from "../../components/Map";
import ConsignmentTrackingButton from "../../components/ConsignmentTrackingButton";

export default function OrderTrackingView({ logoUrl }) {
  return (
    <StyledOrderTrackingWrapper>
      <FlexSectionWrapper>
        <CustomFlexSectionItem flexBasisPercentages={30}>
          <Logo img={logoUrl || logoImg} width={400} scaled marginEnable />
        </CustomFlexSectionItem>
        <CustomFlexSectionItem flexBasisPercentages={70}>
          <OrderBasicData />
        </CustomFlexSectionItem>
      </FlexSectionWrapper>
      <FlexSectionWrapper>
        <FlexSectionItem>
          <BuyerData />
        </FlexSectionItem>
        <FlexSectionItem>
          <RecipientData />
        </FlexSectionItem>
      </FlexSectionWrapper>

      <OrderItemsList />
      <FlexSectionWrapper>
        <FlexSectionItem>
          <AdditionalOrderData />
        </FlexSectionItem>
        <FlexSectionItem>
          <Map />
        </FlexSectionItem>
      </FlexSectionWrapper>
      <ConsignmentTrackingButton />
    </StyledOrderTrackingWrapper>
  );
}
