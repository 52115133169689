export const GLOBAL_STATE_PROPS = Object.freeze({
  BUYER_DATA: "buyerData",
  RECIPIENT_DATA: "recipientData",
  ORDER_DATA: "orderData",
  ORDER_ITEMS: "orderItems",
  LOGO_URL: "logoUrl",
  IS_LOADING: "isLoading",
  IS_LOADED: "isLoaded",
  HAS_ERROR: "hasError",
});

export const GLOBAL_ACTIONS_TYPES = Object.freeze({
  SAVE_DATA: "saveData",
  GET_LOGO_URL: "getLogoUrl",
  START_LOADING: "startLoading",
  FINISH_LOADING: "finishLoading",
  SET_ERROR: "setError",
  RESET_DATA: "resetData",
  RESET_ERROR: "resetError",
});

export const GLOBAL_INITIAL_STATE = Object.freeze({
  [GLOBAL_STATE_PROPS.BUYER_DATA]: {
    name: null,
    firstName: null,
    lastName: null,
    thirdName: null,
    nipNumber: null,
    address: null,
    zipCode: null,
    locality: null,
    country: null,
    phoneNumber: null,
    emailAddress: null,
  },
  [GLOBAL_STATE_PROPS.RECIPIENT_DATA]: {
    firstName: null,
    lastName: null,
    thirdName: null,
    nipNumber: null,
    address: null,
    zipCode: null,
    locality: null,
    phoneNumber: null,
    emailAddress: null,
  },
  [GLOBAL_STATE_PROPS.ORDER_DATA]: {
    number: null,
    date: null,
    status: null,
    paymentForm: null,
    paymentStatus: null,
    deliveryMethod: null,
    waybillNumber: null,
    additionalComments: null,
    provider: null,
    coordinates: { from: [], to: [] },
  },
  [GLOBAL_STATE_PROPS.ORDER_ITEMS]: [],
  [GLOBAL_STATE_PROPS.LOGO_URL]: null,
  [GLOBAL_STATE_PROPS.IS_LOADING]: false,
  [GLOBAL_STATE_PROPS.IS_LOADED]: false,
  [GLOBAL_STATE_PROPS.HAS_ERROR]: false,
});

export const ORDER_STATUSES = Object.freeze({});
